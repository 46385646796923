@import "@/styles/_variables.scss";



























.uk-container {
  height: 100vh;
  z-index: 10;
}
.uk-card {
  margin-top: 5%;
}
h1 {
  font-size: 32px;
}
i {
  z-index: 1;
}
